import React, { useEffect, useState } from 'react';
import './App.css';
import zh_CN from "antd/lib/locale-provider/zh_CN";
import { Table, Button, Dropdown, Menu, Tooltip, Input, ConfigProvider } from 'antd';
import { PicLeftOutlined, EnvironmentOutlined, HistoryOutlined, ExportOutlined, DownOutlined, CodeSandboxOutlined } from '@ant-design/icons';

let timeTaskRef = null;

function App() {
  const [loading, setLoading] = useState(false);
  const [refreshTime, setRefreshTime] = useState("5");

  const columns = [
    { dataIndex: "cardNumber", title: <Button icon={<PicLeftOutlined />} style={{ width: "100%", cursor: "unset" }} type="primary">车牌号</Button>, key: "cardNumber" },
    { dataIndex: "parkingName", title: <Button icon={<EnvironmentOutlined />} style={{ width: "100%", cursor: "unset" }} type="primary" >停车场名称</Button>, key: "parkingName" },
    { dataIndex: "entryTime", title: <Button icon={<HistoryOutlined />} style={{ width: "100%", cursor: "unset" }} type="primary" >入场时间</Button>, key: "entryTime" },
    { dataIndex: "outTime", title: <Button icon={<ExportOutlined />} style={{ width: "100%", cursor: "unset" }} type="primary" >出场时间</Button>, key: "cardNumber" },
  ];

  const sourceData = [];

  useEffect(() => {
    setTimeTask();
  }, []);

  const refresh = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const setTimeTask = (time) => {
    clearInterval(timeTaskRef);
    timeTaskRef = setInterval(() => {
      refresh();
    }, time ? Number(time) * 60 * 1000 : 5 * 60 * 1000);
  };

  const menu = (
    <Menu onClick={(info) => {
      refresh();
      setTimeTask(info.key);
      setRefreshTime(info.key);
    }}>
      {/* <Menu.Item key="0.1">5秒</Menu.Item>
      <Menu.Item key="0.05">1秒</Menu.Item> */}
      <Menu.Item key="5">5分钟</Menu.Item>
      <Menu.Item key="30">30分钟</Menu.Item>
      <Menu.Item key="60">1小时</Menu.Item>
    </Menu>
  );

  return (
    <ConfigProvider locale={zh_CN}>
      <div className="App">
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 30 }}>
          <div>
            <h2>
              <CodeSandboxOutlined />车辆记录查询
            </h2>
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <Input.Search 
                  placeholder="输入车牌号"
                  onSearch={(value) => {
                    if (value) {
                      refresh();
                    }
                  }} 
                  enterButton 
                  style={{ marginRight: 30 }}
               />
              <Dropdown.Button
                  icon={<DownOutlined />}
                  type="primary"
                  overlay={menu}
                  onClick={(e) => {refresh()}}
                >
                  <Tooltip title="点击立即刷新">
                    {`刷新频率(${refreshTime === "60" ? "一小时" : `${refreshTime}分钟`})`}
                  </Tooltip>
                </Dropdown.Button>
            </div>
          </div>
        </div>
        <Table 
          columns={columns} 
          dataSource={sourceData} 
          loading={loading} 
        />
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header> */}
      </div>
    </ConfigProvider>
  );
}

export default App;
